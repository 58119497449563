<template>
    <div>
        <v-row v-if="categorias.isLoading">
            <v-col v-for="(_, index) in Array.from({ length: 4 })" cols="12" sm="6" md="3" :key="index">
                <v-card>
                    <span class="d-block px-4 py-4">
                        <v-skeleton-loader type="text" width="33%" />
                    </span>
                    <v-divider />
                    <span class="d-block px-4 py-4">
                        <v-skeleton-loader type="heading" />
                    </span>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-else>
            <v-col v-for="(categoria, index) in categorias.data" cols="12" sm="6" md="3" :key="index">
                <v-card>
                    <v-card-title class="px-4 py-4">
                        <span class="text-body-2 font-weight-bold text-uppercase primary--text">
                            {{ categoria.titulo }}
                        </span>
                    </v-card-title>
                    <v-divider />
                    <v-card-text class="px-4 py-4">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" class="text-h5 font-weight-bold secondary--text">
                                    {{ toMoneyFormat(categoria.valor, false) }}
                                </span>
                            </template>
                            <span>{{ toMoneyFormat(categoria.valor, false) }}</span>
                            </v-tooltip>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { createLoadable, toggleLoadable, setLoadableResponse } from '@/utils/loadable';
import { toMoneyFormat } from '@/utils/data';

export default {
    name: 'MontosModalidades',
    props: {
        eventoBusqueda: { type: String, required: true },
        listener: { type: Object, required: true },
    },
    data: () => ({
        categorias: createLoadable([]),
    }),
    methods: {
        toMoneyFormat,
        manejarEventos(evento, payload) {
            if (evento !== this.eventoBusqueda) return;

            this.cargarCategorias(payload);
        },
        // DATA
        async cargarCategorias(filtros) {
            toggleLoadable(this.categorias);
            const { data } = await this.services.ReporteriaModalidadesCompra.cargarEstadisticasProcesos(filtros);
            setLoadableResponse(this.categorias, data);
        },
    },
    created() {
        this.cargarCategorias();
        this.listener.setHandler(this.manejarEventos);
    },
}
</script>